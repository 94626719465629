import { FormTabs } from "@wac-ui-dashboard/wac_component_library";
import Basic from "./Basic";
import Family from "./Family";
import Education from "./Education";
import Employement from "./Employement";
import Contact from "./Contact";
import useMemberCreationForm from "./useMemberCreationForm";
import Style from "./memberCreationForm.module.scss";

const MemberCreationForm = ({
  selectedUser,
  isUpdateDataSuccess,
  profileRefetch = false,
  refetchincom,
  canChangePermission
}) => {
  const {
    tabs,
    activeTab,
    isEditProfile,
    isOptionDataSuccess,
    optionsData,
    handleTabClick,
    isUpdateProfile,
    Updatedata
  } = useMemberCreationForm();

  return (
    <div>
      <FormTabs
        propStyle={{ root: Style.root }}
        tabs={tabs}
        active={activeTab}
        handleClick={handleTabClick}
      />
      {activeTab && activeTab === "Basic" ? (
        <Basic
          optionsData={optionsData}
          isEditProfile={isEditProfile}
          profileRefetch={profileRefetch}
          isOptionDataSuccess={isOptionDataSuccess}
          isUpdateDataSuccess={isUpdateDataSuccess}
          className={`pro-w-100`}
          isStickyFooter={false}
          selectedUser={selectedUser}
          isUpdateProfile={isUpdateProfile}
          Updatedata={Updatedata}
          canChangePermission={canChangePermission}
        />
      ) : activeTab === "Job" ? (
        <Employement
          optionsData={optionsData}
          profileRefetch={profileRefetch}
          isEditProfile={isEditProfile}
          isOptionDataSuccess={isOptionDataSuccess}
          className={`pro-w-100`}
          isStickyFooter={false}
          selectedUser={selectedUser}
        />
      ) : activeTab === "Family" ? (
        <Family
          optionsData={optionsData}
          profileRefetch={profileRefetch}
          isEditProfile={isEditProfile}
          isOptionDataSuccess={isOptionDataSuccess}
          className={`pro-w-100`}
          isStickyFooter={false}
          selectedUser={selectedUser}
        />
      ) : activeTab === "Contact" ? (
        <Contact
          optionsData={optionsData}
          profileRefetch={profileRefetch}
          isEditProfile={isEditProfile}
          isOptionDataSuccess={isOptionDataSuccess}
          className={`pro-w-100`}
          isStickyFooter={false}
          selectedUser={selectedUser}
          refetchincom={refetchincom}
        />
      ) : activeTab === "Edu" ? (
        <Education
          optionsData={optionsData}
          profileRefetch={profileRefetch}
          isEditProfile={isEditProfile}
          isOptionDataSuccess={isOptionDataSuccess}
          className={`pro-w-100`}
          isStickyFooter={false}
          selectedUser={selectedUser}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default MemberCreationForm;

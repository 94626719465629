import { createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";


const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  sortBy: "",
  sortOrder: "desc",
  search: "",
  selectedItemsDetails: "",
  showCreateModal: false,
  magazineId: "",
  clearSelection: false,
  isEdit:false,
  itemId:"",
  deleteModal:false
};

/**
 * @example {
 *  "sort_by":"name"
 *  "sort_order":"asc"

 *  }
 */

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = bannerSlice.actions;

export default bannerSlice.reducer;

import { combineReducers } from "@reduxjs/toolkit";
import { members } from "./queries/members";
import { staff } from "./queries/settings";
import { payment } from "./queries/payment";
import { usersRtk } from "./queries/users";
import roleSlice from "./slices/Settings/roleSlice";
import staffSlice from "./slices/Settings/staffSlice";
import createMemberSlice from "./slices/Users/createMemberSlice";
import incompleteSlice from "./slices/Users/incompleteSlice";
import membersSlice from "./slices/Users/membersSlice";
import membersVerificationSlice from "./slices/Users/verificationSlice";
import messagesSlice from "./slices/Users/messagesSlice";

import globalSlice from "./slices/Global";
import blockedSlice from "./slices/Users/blockedSlice";
import reportedSlice from "./slices/Users/reportedSlice";
import packageSlice from "./slices/Settings/packageSlice";
import transactionsSlice from "./slices/payment/transactionsSlice";
import followUpSlice from "./slices/payment/followUpSlice";
import adonsSlice from "./slices/Settings/adonsSlice";
import profileSlice from "./slices/Profile";
import { profile } from "./queries/Profile";
import Matches from "./slices/Profile/Matches";
import testimonialSlice from "./slices/Settings/testimonialsSlice";
import faqs from "./slices/Settings/faqsSlice";
import branchesSlice from "./slices/Settings/branchesSlice";
import branchesDetailsPageSlice from "./slices/Settings/branchesDetailsPageSlice";
import dioceseSlice from "./slices/Settings/dioceseSlice";
import transferSlice from "./slices/Notifications/transfersSlice";
import deletedAccountsSlice from "./slices/Users/deletedAccountsSlice";
import dataUpdateSlice from "./slices/Notifications/dataUpdateSlice";

import usersListSlice from "./slices/CMS/usersList";
import highlightedProfilesSlice from "./slices/CMS/useHighlightedProfilesSlice";

import { faqsRtk } from "./queries/cms";
import { notificationRtk } from "./queries/notifications";
import ShortListed from "./slices/Profile/ShortListed";
import Favourites from "./slices/Profile/Favourites";
import Viewed from "./slices/Profile/Viewed";
import Interested from "./slices/Profile/Interested";
import ContactViewed from "./slices/Profile/ContactViewed";
import ProfilePayment from "./slices/Profile/ProfilePayment";
import { dashboard } from "./queries/Dashboard";
import ProfileFollowup from "./slices/Profile/ProfileFollowup";
import dioceseSinglePageSlice from "./slices/Settings/dioceseSinglePageSlice";
import ProfileActivity from "./slices/Profile/ProfileActivity";
import notificationSlice from "./slices/Notifications/notificationSlice";
import subscribersSlice from "./slices/CMS/subscribersSlice";
import magazineSlice from "./slices/CMS/magazineSlice";
import stateSlice from "./slices/Settings/stateSlice";
import stateSingleSlice from "./slices/Settings/stateSingleSlice";
import updateIncompletememberSlice from "./slices/Users/updateIncompletememberSlice";
import incompleteRegSlice from "./slices/Users/incompleteReg.Slice";
import bannerSlice from "./slices/CMS/bannerSlice";

const appReducer = combineReducers({
  [members.reducerPath]: members.reducer,
  [staff.reducerPath]: staff.reducer,
  [payment.reducerPath]: payment.reducer,
  [profile.reducerPath]: profile.reducer,
  [faqsRtk.reducerPath]: faqsRtk.reducer,
  [notificationRtk.reducerPath]: notificationRtk.reducer,
  [usersRtk.reducerPath]: usersRtk.reducer,
  [dashboard.reducerPath]: dashboard.reducer,
  createMembers: createMemberSlice,
  members: membersSlice,
  staff: staffSlice,
  role: roleSlice,
  package: packageSlice,
  adons: adonsSlice,
  incomplete: incompleteSlice,
  blocked: blockedSlice,
  reported: reportedSlice,
  membersVerification: membersVerificationSlice,
  global: globalSlice,
  transactions: transactionsSlice,
  profile: profileSlice,
  matches: Matches,
  testimonials: testimonialSlice,
  faqs: faqs,
  branches: branchesSlice,
  branchesDetailsPage: branchesDetailsPageSlice,
  dioceseListingPage: dioceseSlice,
  dioceseSinglePage: dioceseSinglePageSlice,
  shortListed: ShortListed,
  favourites: Favourites,
  viewed: Viewed,
  interested: Interested,
  contactViewed: ContactViewed,
  profilePayment: ProfilePayment,
  transfer: transferSlice,
  usersList: usersListSlice,
  highlightedProfiles: highlightedProfilesSlice,
  dataUpdation: dataUpdateSlice,
  followup: followUpSlice,
  deletedAccounts: deletedAccountsSlice,
  messages: messagesSlice,
  profileFollowup: ProfileFollowup,
  profileActivity: ProfileActivity,
  notification: notificationSlice,
  subscribers: subscribersSlice,
  magazine: magazineSlice,
  statesListingPage:stateSlice,
  stateSinglePage:stateSingleSlice,
  incompletereg:incompleteRegSlice,
  updateIncompletemember:updateIncompletememberSlice,
  banner:bannerSlice
});

export default appReducer;

import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes,
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import Index from "../pages";
import AuthModule from "../components/Auth";
import ForgotPassword from "../components/Auth/ForgotPassword";
import Login from "../components/Auth/Login";
import ResetPassword from "../components/Auth/ResetPassword";
import Users from "../components/Users";
import Members from "../components/Users/Members";
import Verification from "../components/Users/Verification";
import Staff from "../components/Configure/Staff";
import Settings from "../components/Configure";
import Incomplete from "../components/Users/Incomplete";
import Reported from "../components/Users/Reported";
import Blocked from "../components/Users/Blocked";
import Roles from "../components/Configure/Roles";
import Packages from "../components/Configure/Packages";
import Adons from "../components/Configure/Adons";
import ManageRoles from "../components/Configure/Roles/ManageRoles";
import Payment from "../components/Payment";
import Transactions from "../components/Payment/Transactions";
import FollowUp from "../components/Users/FollowUp";
import Profile from "../pages/Profile";
import Overview from "../components/Profile/Overview";
import Matches from "../components/Profile/Matches";
import Shortlisted from "../components/Profile/Shortlisted";
import Favorites from "../components/Profile/Favorites";
import Viewed from "../components/Profile/Viewed";
import Interested from "../components/Profile/Interested";
import ContactViewed from "../components/Profile/ContactViewed";
import ProfilePayment from "../components/Profile/ProfilePayment";
import Activity from "../components/Profile/Activity";
import ProfileSettings from "../components/Profile/ProfileSettings";
import Testimonials from "../components/CMS/Testimonial";
import FAQs from "../components/CMS/FAQ";
import Branches from "../components/Configure/Branches";
import BranchSingle from "../components/Configure/BrancheSingle";
import Diocese from "../components/Configure/Diocese";
import DioceseSingle from "../components/Configure/DioceseSingle";
import Transfers from "../components/Support/Transfers";
import CMS from "../components/CMS";
import UsersList from "../components/CMS/UsersList";
import HighlightedProfiles from "../components/CMS/HighlightedProfiles";
import DataUpdation from "../components/Support/DataUpdation";
import Deleted from "../components/Users/Deleted";

// import Requests from "../components/Users/Requests";
import Appearance from "../components/Configure/Appearance";
import useTheme from "../components/Configure/Appearance/useTheme";
import Dashboard from "../components/Dashboard";
import DashboardListing from "../components/Dashboard/DashboardListing";
import ProfileFollowUp from "../components/Profile/ProfileFollowUp";
import Messages from "../components/Support/Complaints";
import Support from "../components/Support";
import NotificationListing from "../components/Notification/NotificationListing";
import Notification from "../components/Notification";
import Subscribers from "../components/CMS/Subscribers";
import Magazine from "../components/CMS/Magazine";
import PartnerDashboardListing from "../components/Dashboard/PartnerDashboardListing";
import States from "../components/Configure/States";
import StatesSingle from "../components/Configure/StateSingle";
import IncompleteRegistration from "../components/Users/IncompleteRegistration";
import BannerImage from "../components/CMS/BannerImage";

const Routes = () => {
  useTheme("light");

  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="/" element={<Index />} />

        <Route path="dashboard" element={<Dashboard />}>
          <Route path="" element={<DashboardListing />} />
          <Route path="director" element={<PartnerDashboardListing />} />
        </Route>
        {/* <Route path="director-dashboard" element={<Dashboard />}>
          <Route path="" element={<PartnerDashboardListing />} />
        </Route> */}

        <Route path="notifications" element={<Notification />}>
          <Route path="" element={<NotificationListing />} />
        </Route>

        <Route path="users" element={<Users />}>
          <Route path="" element={<Members />}></Route>
          <Route path="verification" element={<Verification />}></Route>
          <Route path="incomplete" element={<Incomplete />}></Route>
          <Route path="reported" element={<Reported />}></Route>
          <Route path="blocked" element={<Blocked />}></Route>
          <Route path="deleted-accounts" element={<Deleted />}></Route>
          <Route path="testimonial" element={<Testimonials />}></Route>
          <Route path="faqs" element={<FAQs />}></Route>
          <Route path="followup" element={<FollowUp />}></Route>
          <Route path="in-complete-user" element={<IncompleteRegistration/>}></Route>
          

          {/* <Route path="requests" element={<Requests />}></Route> */}
        </Route>
        <Route path="configure" element={<Settings />}>
          <Route path="staff" element={<Staff />}></Route>
          <Route path="roles" element={<Roles />}></Route>
          <Route path="roles/permission" element={<ManageRoles />}></Route>
          <Route path="packages" element={<Packages />}></Route>
          <Route path="adons" element={<Adons />}></Route>
          <Route path="branches" element={<Branches />}></Route>
          <Route path="appearance" element={<Appearance />}></Route>
          <Route
            path="branches/single/:branchID"
            element={<BranchSingle />}
          ></Route>
          <Route path="diocese" element={<Diocese />}></Route>
          <Route
            path="diocese/single/:dioceseID"
            element={<DioceseSingle />}
          ></Route>
           <Route path="states" element={<States />}></Route>
          <Route
            path="states/single/:statesID"
            element={<StatesSingle />}
          ></Route>
        </Route>
        <Route path="/login" element={<AuthModule />}>
          <Route path="" element={<Login />}></Route>
          <Route path="forgot-password" element={<ForgotPassword />}></Route>
          <Route path="reset-password" element={<ResetPassword />}></Route>
        </Route>
        <Route path="payment" element={<Payment />}>
          <Route path="transactions" element={<Transactions />}></Route>
        </Route>

        <Route path={"support"} element={<Support />}>
          <Route path="data-updation" element={<DataUpdation />}></Route>
          <Route path="transfers" element={<Transfers />}></Route>
          <Route path="complaints" element={<Messages />}></Route>
        </Route>

        <Route path="cms" element={<CMS />}>
          <Route path="faq" element={<FAQs />}></Route>
          <Route path="testimonials" element={<Testimonials />}></Route>
          <Route path="user-list" element={<UsersList />}></Route>
          <Route
            path="highlighted-profiles"
            element={<HighlightedProfiles />}
          ></Route>
          <Route
            path="newsletter-subscribers"
            element={<Subscribers />}
          ></Route>
          <Route path="magazine" element={<Magazine />}></Route>
          <Route path="banners" element={<BannerImage />}></Route>
        </Route>

        <Route path="profile" element={<Profile />}>
          <Route path="" element={<Overview />}></Route>
          <Route path="matches" element={<Matches />} />
          <Route path="shortlisted" element={<Shortlisted />} />
          <Route path="favorites" element={<Favorites />} />
          <Route path="viewed" element={<Viewed />} />
          <Route path="interested" element={<Interested />} />
          <Route path="contact-viewed" element={<ContactViewed />} />
          <Route path="profile-payment" element={<ProfilePayment />} />
          <Route path="followup" element={<ProfileFollowUp />} />
          <Route path="activity" element={<Activity />} />
          <Route path="settings" element={<ProfileSettings />} />
        </Route>
        <Route path={"/*"} element={<Navigate to={"/"} />} />
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default Routes;

import { useState } from "react";
import Style from "./testimonials.module.scss";
import Select from "react-select";
import { changeStatus } from "./api";
import { toast } from "react-toastify";

const StatusButton = ({ data, refetch }) => {
    const [status, setStatus] = useState(
      data?.status === "Live"
        ? { value: 1, label: "Live" }
        : { value: 0, label: "Hold" }
    );
  
    const options = [
      { value: 1, label: "Live" },
      { value: 0, label: "Hold" },
    ];
  
    const handleChange = (selectedOption) => {
      setStatus(selectedOption);
      let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("package_id", data?._id);
        formData.append("status", selectedOption?.value);
    
      ;
      changeStatus(formData).then((res) => {
        if (res?.data?.status_code === 200) {
          refetch();
          toast.success("Status Updated Successfully");
        } else {
          toast.error("Failed to Update Status");
        }
      });
    };
  
    return (
      <div className="col-12 no-border" style={{ minWidth: "222px" }}>
        <Select
          options={options}
          value={status}
          onChange={handleChange}
          className={Style.reactSelectContainer}
          classNamePrefix="pro-input"
          menuPosition="fixed"
          menuPlacement="auto"
        />
      </div>
    );
  };
  export default StatusButton
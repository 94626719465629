import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import Style from "./banner.module.scss";
import { ProfileCard } from "../UsersList/ProfileCard";
// import MagazineForm from "./MagazineForm";
import DatePickerRsuite from "../../Global/CustomDateRangePicker";
import useBannerImage from "./useBannerImage";
import BannerForm from "./BannerForm";

const BannerImage = () => {
  const {
    banners,
    handleEditAction,
    showCreateModal,
    hasCreatePermission,
    isFetching,
    isLoading,
    showEditModal,
    tableFields,
    bannerState,
    currentPage,
    paginationOptions,
    imageData,
    actionOptions,
    showImageModal,
    hasEditPermission,
    hasDeletePermission,
    handleDateRangeChange,
    closeImageModal,
    handleActionChange,
    refetch,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    handlePagination,
    closeEditModal,
    handleDateChange,
    handlePageSize,
    updateBannerFields,
    handleClearClick,
    date,
    isEdit,
    handleDeleteCloseModal,
    handleDeleteModal,
    handleDeleteAction
  } = useBannerImage();
  return (
    <div>
      <HeadingGroup
        title={"Banners"}
        buttonTitle={hasCreatePermission === 1 ? "Add new" : ""}
        className={`pro-mb-4`}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="pro-d-flex search-filter-wrap">
          <SearchFilters
            //showDateRange
            dropDownFilter={
              <div className="col-auto">
                <DatePickerRsuite
                  defaultDateRange={date}
                  handleDateRangeChange={handleDateRangeChange}
                />
              </div>
            }
            onDateChange={handleDateChange}
            onSearchInput={handleSearch}
            showActions={false}
            handleActionClick={handleEditClick}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            showClearFilters
            handleClear={handleClearClick}
            initialDateRange={date}
            searchInputProps={{ value: bannerState?.search }}
            actionOptions={actionOptions?.filter((item) => item.value === 0)}
            onActionOptionChange={handleActionChange}
          />
        </div>
        {banners?.data?.data?.data?.length !== 0 ? (
          <div className={"pro-pt-3"}>
            <Table
              data={banners?.data?.data?.data || []}
              uniqueID={"_id"}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              showCheckBox={hasEditPermission === 1 ? true : false}
              //fields={adons?.data?.fields}
              handleEdit={handleEditAction}
              clear={bannerState.clearSelection}
              multiSelect={false}
              assignable={false}
              fields={tableFields}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              handleDelete={handleDeleteModal}
              deleteIcon={<span className="material-symbols-outlined">delete</span>}
              deletable={hasDeletePermission=== 1 ? true : false}
              getRow={getRow}
              loading={isFetching}
              perpage={bannerState?.currentPageSize}
              extraClasssName={"dashboard_no_padding"}
            />
            {banners?.data?.data?.data?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalPageCount={banners?.data?.data?.last_page}
                defaultValue={paginationOptions?.filter(
                  (item) => item.value === bannerState?.currentPageSize
                )}
                onPageChange={handlePagination}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
              />
            )}
          </div>
        ) : (
          <EmptyData />
        )}
        <OffCanvasLayout
          show={showCreateModal}
          handleClose={closeModal}
          title={isEdit?"Update Banner":"Create Banner"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <BannerForm
            refetch={refetch}
            isStickyFooter
            closeModal={closeModal}
            isEdit={isEdit}
          />
        </OffCanvasLayout>
        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={banners?.data?.fields}
              moduleId={banners?.data?.module_id}
              updateData={updateBannerFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          show={bannerState?.deleteModal}
          handleClose={handleDeleteCloseModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure you want to delete?"}
              subTitle={"This action cannot be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteCloseModal}
              submitText={"Yes"}
              submitAction={handleDeleteAction}
              isRight={true}
            />
          </div>
        </ModalLayout>
        <ModalLayout  propStyle={{
          root : `${Style.modal_root} ${imageData?.type==="desktop"? Style.desktop : imageData?.type==="mobile" ? Style.mobile : imageData?.type==="popup" ? Style.popup : ""}`
        }} centered show={showImageModal} handleClose={closeImageModal}>
          <ProfileCard data={imageData?.data} handleClose={closeImageModal} />
        </ModalLayout>
      </div>
    </div>
  );
};

export default BannerImage;

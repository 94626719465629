import {
  Actions,
  Button,
  Header,
  ModalLayout,
  MultiColumnTable,
  NavGroup,
  ProfileSideBar,
  // SimpleImageSlider,
  SimpleTextDetails,
  TabHeading,
  WarningBox,
  OffCanvasLayout,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import { ProfileCard } from "../../../components/Global/ProfileCard";
import { Link, Outlet } from "react-router-dom";
import HeaderActions from "../CommonLayout/HeaderActions";
import Style from "../CommonLayout/commonLayout.module.scss";
import Assets from "../../../assets/Assets";
import useProfileLayout from "./useProfileLayout";
import profileStyle from "./profileLayout.module.scss";
import ExportProfileDetails from "./ExportPofileDetails";
import MemberCreationForm from "../../../components/Users/Members/MemberCreationForm";
import ProfileLayoutSidebarShimmer from "../../../components/Global/Shimmers/ProfileLayoutSidebarShimmer";
import ProtectRoute from "../../../utils/components/ProtectRoute/ProtectRoute";
import useCommonLayout from "../CommonLayout/useCommonLayout";
import { SimpleImageSlider } from "../../../components/Global/SimpleImageSlider";

const ProfileLayout = ({ navigation }) => {
  const {
    printRef,
    profiles,
    activeProfile,
    isFetching,
    imageSlider,
    profileData,
    actions,
    basicDetails,
    show,
    showCreateModal,
    showDeleteImageModal,
    activeImageIndex,
    hasHighlightPermission,
    hasUpdatePermission,
    setActiveImageIndex,
    handleDeleteImage,
    closeDeleteImageModal,
    handleActionDeleteImage,
    setShowDeleteImageModal,
    setShow,
    handleHighlightProfile,
    handleUnHighlightProfile,
    closeModal,
    refetch,
    handleClick,
    handleClose,
    handleEditClick,
    handleProfileClick,
    closeEditOffcanvas,
    onProfileUpdateClick,
  } = useProfileLayout();

  const { navigations, globalState } = useCommonLayout();

  function getPackageStatus(expiryDate) {
    const [day, month, year] = expiryDate.split("-");
    const formattedExpiryDate = `${year}-${month}-${day}`;

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const packageExpiry = new Date(formattedExpiryDate);

    if (currentDate <= packageExpiry) {
      return "Package Expiry";
    } else {
      return "Package Expired on";
    }
  }

  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADERLOGO
            : Assets.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />
      <div className="pt-custom">
        <TabHeading
          data={profiles}
          uniqueId={"id"}
          activeId={activeProfile}
          handleClick={handleClick}
          handleClose={handleClose}
        />
      </div>
      <div className={`pro-w-100 ${profileStyle.main_wrapper}`}>
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(profileData?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                {/**#FIXME handle deleted accounts */}
                <ProfileSideBar
                  extraClassName={profileStyle.left_sidebar_sticky}
                >
                  <div className={`pro-px-5 pro-pt-5`}>
                    <div className={`${profileStyle.left_sidebar_sticky_top}`}>
                      <SimpleImageSlider
                        data={imageSlider?.sort(
                          (a, b) => b.isPrimary - a.isPrimary
                        )}
                        width={329.7}
                        height={329.55}
                        handleClick={handleProfileClick}
                        isPremium={profileData?.data?.premium_status}
                        status={profileData?.data?.status || 1}
                      />

                      <SimpleTextDetails
                        isOnline={profileData?.data?.last_login ? true : false}
                        title={`${profileData?.data?.first_name ?? ""} ${
                          profileData?.data?.last_name ?? ""
                        } ${
                          profileData?.data?.age
                            ? `(${profileData?.data?.age})`
                            : ""
                        }`}
                        uId={profileData?.data?.profile_id ?? ""}
                        lastOnline={
                          profileData?.data?.last_login &&
                          `Last login: ${profileData?.data?.last_login ?? ""}`
                        }
                        extraClassNames={`pro-mt-4 pro-mb-2 ${profileStyle.profile_name_wrap}`}
                      />

                      {profileData?.data?.package_expiry_date ? (
                        <div>
                          <p className="pro-align-self-center pro-mb-2">
                            {` ${
                              getPackageStatus(
                                profileData?.data?.package_expiry_date
                              ) ?? ""
                            } : ${
                              profileData?.data?.package_expiry_date ?? ""
                            } `}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}

                      {hasHighlightPermission === 1 ? (
                        <>
                          {profileData?.data?.profile_highlighted === 0 &&
                          profileData?.data?.assigned_profile === 1 ? (
                            <div className="pro-my-4">
                              <Button
                                className="pro-btn pro-btn-outline-primary"
                                onClick={() =>
                                  hasHighlightPermission === 1
                                    ? handleHighlightProfile(1)
                                    : () => {}
                                }
                              >
                                Highlight this profile
                              </Button>
                            </div>
                          ) : (
                            <div className="pro-d-flex pro-gap-4 pro-my-4">
                              <div>
                                <Button
                                  className="pro-btn pro-btn-primary"
                                  onClick={() =>
                                    hasHighlightPermission === 1
                                      ? handleUnHighlightProfile()
                                      : () => {}
                                  }
                                >
                                  Highlighted
                                </Button>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      <div className={`${profileStyle.assign_wrap} pro-py-2`}>
                        {profileData?.data?.assigned_staff?.name ? (
                          <>
                            <p className="pro-align-self-center pro-mb-0 pro-fw-medium">
                              {`Assigned Staff : ${
                                profileData?.data?.assigned_staff?.name ?? ""
                              } `}
                            </p>
                            <p className=" pro-align-self-center pro-mb-0">
                              {`${
                                profileData?.data?.assigned_staff?.profile_id ??
                                ""
                              } | ${
                                profileData?.data?.assigned_staff
                                  ?.phone_number ?? ""
                              }`}
                            </p>
                          </>
                        ) : (
                          <p className="pro-align-self-center pro-mb-0 pro-fw-medium">
                            {`Staff Unassigned`}
                          </p>
                        )}
                      </div>

                      <Actions
                        actions={actions}
                        extraClassName={`pro-mt-1 pro-mb-5 ${profileStyle.profile_layout_action}`}
                      />
                    </div>
                    <div
                      className={`${profileStyle.left_sidebar_sticky_scrollWrap}`}
                    >
                      <div style={{ marginBottom: "20px" }}>
                        <WarningBox
                          percentage={profileData?.data?.percentage}
                          title={"Profile Complete"}
                          button={
                            profileData?.data?.percentage < 100 &&
                            hasUpdatePermission === 1 &&
                            profileData?.data?.assigned_profile === 1
                              ? "Update"
                              : ""
                          }
                          handleClick={onProfileUpdateClick}
                          type={`type-2`}
                        />
                      </div>
                      <MultiColumnTable
                        title={"Basic Details"}
                        data={basicDetails}
                        extraClassNames={`pro-mb-5`}
                        editIcon={
                          hasUpdatePermission === 1 &&
                          profileData?.data?.assigned_profile === 1 ? (
                            <span className="material-symbols-outlined">
                              edit_square
                            </span>
                          ) : (
                            false
                          )
                        }
                        handleEdit={handleEditClick}
                      />
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>

          <ModalLayout show={show} handleClose={closeModal} backdrop="static">
            <ProfileCard
              data={imageSlider?.sort((a, b) => {
                if (a.isRejected && !b.isRejected) {
                  return 1;
                } else if (!a.isRejected && b.isRejected) {
                  return -1;
                } else {
                  return b.isPrimary - a.isPrimary;
                }
              })}
              profileData={profileData}
              handleClose={closeModal}
              userId={activeProfile}
              activeImageIndex={activeImageIndex}
              setActiveImageIndex={setActiveImageIndex}
              refetch={refetch}
              handleDeleteImage={handleDeleteImage}
              closeDeleteImageModal={closeDeleteImageModal}
              setShow={setShow}
              showDeleteImageModal={showDeleteImageModal}
              setShowDeleteImageModal={setShowDeleteImageModal}
              hasUpdatePermission={hasUpdatePermission}
            />
          </ModalLayout>

          <ModalLayout
            centered={false}
            show={showDeleteImageModal}
            handleClose={closeDeleteImageModal}
          >
            <div className="pro-m-5">
              <ConfirmationBox
                title={`Are you sure you want to delete this Image?`}
                isRight={true}
                cancelText={`No`}
                submitText={`Yes`}
                cancelAction={closeDeleteImageModal}
                submitAction={() => handleActionDeleteImage()}
              >
                ConfirmationBox
              </ConfirmationBox>

              {true && <span className="error-message">{true}</span>}
            </div>
          </ModalLayout>

          <OffCanvasLayout
            show={showCreateModal}
            handleClose={closeEditOffcanvas}
            title={`${"Update Member Details"}`}
            closeIcon={
              <span className="material-symbols-outlined"> close </span>
            }
          >
            <MemberCreationForm profileRefetch={true} 
             canChangePermission={profileData?.permission?.find(v => v?.hasOwnProperty('can_change_diocese'))?.can_change_diocese}
            />
          </OffCanvasLayout>

          <div className={profileStyle.pdf_container}>
            <ExportProfileDetails
              ref={printRef}
              activeProfile={activeProfile}
            />
          </div>
          {/* main container */}
          <div className={`${profileStyle.main_container}`}>
            <div className={`${profileStyle.main_container_inner}`}>
              <NavGroup navigations={navigation} type={"type-2"} Link={Link} />
              <Outlet context={{ activeProfile }} />
            </div>
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default ProfileLayout;

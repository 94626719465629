import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import Style from "./bannerForm.module.scss";
import { limitStrLength } from "../../../../utils/functions/table";
import useBannerForm from "./useBannerForm";

const BannerForm = ({
  refetch,
  closeModal,
  isStickyFooter,
  isEdit,
}) => {
  const {
    typeOption,
    formik,
    fullImagePreview,
    responsiveImagePreview,
    popupImagePreview,
    handleFullImage,
    handlePopupImage,handleResponsiveImage,
    selectedItemsDetails,
    profilefileInputRef
  } = useBannerForm({ refetch, closeModal });

  return (
    <div className={`pro-w-100`}>
      <div className="pro-mb-4">
        <Input
          label={"Name"}
          type="text"
          id="name"
          name="name"
          className={`pro-input lg ${
            formik.errors.name && formik.touched.name && " error"
          }`}
          {...formik.getFieldProps("name")}
          error={(formik?.errors?.name&& formik.touched.name) ? formik?.errors?.name : ""}
          errorMessage={(formik?.errors?.name&& formik.touched.name) ? formik?.errors?.name : ""}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="content" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Content
        </label>
        <textarea
          id="content"
          rows={3}
          name="content"
          className={`pro-input lg  ${
            formik.touched.content && formik.errors.content && " error"
          }`}
          {...formik.getFieldProps("content")}
          value={formik?.values?.content ?? ""}
          // onChange={(e) => formik.setFieldValue("answer", e.target.value)}
        ></textarea>
        {formik.touched.content && formik.errors.content && (
          <span className="error-text">{formik.errors.content}</span>
        )}{" "}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="type" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Type
        </label>
        <Select
          id="type"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.type && formik.errors.type && " error"
          }`}
          classNamePrefix="pro-input"
          options={typeOption??[]}
          value={typeOption?.filter(
            (m) => formik.values.type === m?.value
          )}
          onBlur={formik.handleBlur("type")}
          onChange={(value) => {
            formik.setFieldValue("type", value?.value || null);
          }}
        />
        {formik.touched.type && formik.errors.type && (
          <span className="error-text">{formik.errors.type}</span>
        )}{" "}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Full Image*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg, .png, .webp"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.errors.full_image && formik.touched.full_image && " error"
            }`}
            id="full_image"
            name="full_image"
            onBlur={formik.handleBlur("full_image")}
            onChange={(e) => handleFullImage(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.full_image?.[0]?.name !== undefined &&
                selectedItemsDetails === ""
                  ? limitStrLength(formik?.values?.full_image?.[0]?.name, 30)
                  : formik?.values?.full_image?.name !== undefined &&
                    selectedItemsDetails !== ""
                  ? limitStrLength(formik?.values?.full_image?.name??formik?.values?.full_image, 30)
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.full_image && formik.errors.full_image && (
            <span className="error-text">{formik.errors.full_image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={fullImagePreview}
            alt={`magazine - 01`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Responsive Image*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg, .png, .webp"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.errors.responsive_image &&
              formik.touched.responsive_image &&
              " error"
            }`}
            id="responsive_image"
            name="responsive_image"
            onBlur={formik.handleBlur("responsive_image")}
            onChange={(e) => handleResponsiveImage(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.responsive_image?.[0]?.name !== undefined &&
                selectedItemsDetails === ""
                  ? limitStrLength(
                      formik?.values?.responsive_image?.[0]?.name,
                      30
                    )
                  : formik?.values?.responsive_image?.name !== undefined &&
                    selectedItemsDetails !== ""
                  ? limitStrLength(formik?.values?.responsive_image?.name, 30)
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.responsive_image &&
            formik.errors.responsive_image && (
              <span className="error-text">
                {formik.errors.responsive_image}
              </span>
            )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={responsiveImagePreview}
            alt={`magazine - 01`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Popup Image*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg, .png, .webp"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.errors.popup_image &&
              formik.touched.popup_image &&
              " error"
            }`}
            id="popup_image"
            name="popup_image"
            onBlur={formik.handleBlur("popup_image")}
            onChange={(e) => handlePopupImage(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.popup_image?.[0]?.name !== undefined &&
                selectedItemsDetails === ""
                  ? limitStrLength(formik?.values?.popup_image?.[0]?.name, 30)
                  : formik?.values?.popup_image?.name !== undefined &&
                    selectedItemsDetails !== ""
                  ? limitStrLength(formik?.values?.popup_image?.name, 30)
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.popup_image && formik.errors.popup_image && (
            <span className="error-text">{formik.errors.popup_image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={popupImagePreview}
            alt={`magazine - 01`}
          />
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button
          className={`pro-btn-primary lg pro-ms-3 ${formik.isSubmitting ? "loading" : ""}`}
          type="submit"
          disabled={formik.isSubmitting}
          onClick={formik.handleSubmit}
        >
          {!isEdit ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default BannerForm;
